.navbar {
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    z-index: 100;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
}

.navbar-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    margin-left: 20px;
    cursor: pointer;
}