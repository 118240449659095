.wrapper {
    height: 100vh;
    width: 100vw;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
}

.more-info {
    left: 0;
    margin-left: 100px;
    color:rgb(255, 255, 255);
    text-align: left;
    width: 350px;
}