.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    text-align: center;
    color: white;
    backdrop-filter: blur(1px);
    height: 100vh;
  }
  
  .hero-section h1 {
    font-size: 3em;
    margin-bottom: 10px;
  }
  
  .hero-section p {
    margin: 0;
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .hero-section img {
    margin-bottom: 30px;
  }
  
  .hero-section button {
    background-color: #4CAF50;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border: none;
  }
  