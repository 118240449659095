.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  color: white;
  padding: 20px;
  font-size: calc(10px + 2vmin);
}

main {
  margin: 20px;
}

footer {
  padding: 20px;
  background-color: #282c34;
  color: white;
}

.fp-watermark {
  display: none;
}

/* Change the background color of the dots */
#fp-nav ul li a span {
  background: #3b537d; /* or any color */
}

/* Change the dot size */
/* #fp-nav ul li a span {
  width: 10px;
  height: 10px;
} */

/* Change color of the active dot */
#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span {
  background: #8ca8cb; /* or any color */
}

/* Change the hover effect of the dots */
#fp-nav ul li a:hover span {
  background: #555; /* or any color */
}

.section {
  background: 0 !important;
}
 
